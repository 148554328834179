
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        


























































































































































































































































.performance-audience-selection {
  &__heading {
    padding: 0 $--clb-space-4;

    &--title {
      margin: 0;
      margin-bottom: $--clb-space-2;
      font-size: $--clb-h3__font-size;
      text-transform: capitalize;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      span {
        cursor: pointer;
        display: inline;
        font-size: $--clb-font-size-base;
        color: $--clb-body-font;
      }
    }

    &--subtitle {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      text-transform: capitalize;

      &-id {
        white-space: nowrap;
      }

      &-name {
        margin-left: $--clb-space-2;
        margin-top: 0;
        margin-bottom: 0;
        white-space: nowrap;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &--picker {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: $--clb-space-3;
      margin-bottom: $--clb-space-4;

      .date-picker-filter__calendar {
        margin-top: $--clb-space-2;
      }

      .el-input__inner {
        min-height: 40px !important;

        .el-range-separator {
          height: 25px;
        }
      }
    }

    &--toggle {
      display: block;
      margin-top: $--clb-space-3;

      .el-switch__label {
        * {
          font-size: $--clb-font-size-xs;
          font-weight: $--clb-font-weight__bold;
          transition: all 0.5s ease;
          color: $--clb-color-grey__grey;
        }

        &.is-active * {
          color: $--clb-color-primary;
          transition: all 0.5s ease;
        }
      }
    }
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $--clb-space-4;
    border-top: 1px solid $--clb-color-grey__grey-lighter;
    border-bottom: 1px solid $--clb-color-grey__grey-lighter;
    z-index: 1;
    box-shadow: 0 30px 50px 0 rgba(96, 96, 99, 0.05);

    &--add-item {
      min-width: $--clb-layout-3;
      display: flex;
      align-items: center;

      .el-icon-circle-plus {
        color: $--clb-color-primary;
        cursor: pointer;
      }
    }

    &--search {
      display: flex;
      align-items: center;
      padding-left: $--clb-space-4;
      justify-content: flex-end;
      border-left: 1px solid $--clb-color-grey__grey-lighter;

      .el-icon-search {
        font-size: $--clb-font-size-sm;
      }

      input {
        padding-left: $--clb-space-5;
        border: none;
        font-size: $--clb-font-size-sm;

        &::placeholder {
          text-transform: capitalize;
          color: $--color-text-jb-placeholder;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  &__cascade {
    position: relative;
    width: 100%;
    flex: 1;
  }

  &__audience-row-wrapper {
    padding: $--clb-space-4;
    text-transform: capitalize;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    transition: background-color 200ms ease-in-out;

    .el-icon-arrow-down {
      margin-left: $--clb-space-2;
      color: $--clb-color-grey__grey-light;
      font-weight: $--clb-font-weight__semi-bold;
      transform-origin: center;
      transition: transform 200ms ease-in-out, color 200ms ease-in-out;

      &:hover {
        color: $--clb-color-grey__grey;
      }
    }
  }

  &__audience-info {
    display: flex;
    align-items: flex-start;
    font-weight: $--clb-font-weight__semi-bold;
    max-width: 149px;
  }

  &__audience-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: none;
  }

  &__audience-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .el-icon-view,
    .el-icon-more {
      cursor: pointer;
      color: $--clb-color-grey__grey-light;
      transition: 200ms ease-in-out;

      &:hover {
        color: $--clb-color-grey__grey;
      }
    }

    .el-icon-view {
      font-weight: $--clb-font-weight__bold;
      color: $--clb-color-grey__grey-dark;
    }

    .el-checkbox {
      .el-checkbox__input {
        visibility: hidden;
        position: absolute;
        top: -9999px;
        left: -9999px;
        z-index: -1;
      }

      .el-checkbox__label {
        padding: 0 $--clb-space-2;
      }
    }

    .el-icon-more {
      transform: rotate(90deg);
    }

    ul.el-dropdown-menu.el-popper {
      cursor: pointer;
      padding: $--clb-space-2 $--clb-space-3 $--clb-space-3 $--clb-space-3;
      transition: background-color 0.2s ease-in-out;

      &__item {
        :hover {
          background: $--clb-color-secondary__light;
        }
      }
    }
  }

  &__audience-attribute-list {
    position: relative;
    z-index: 1;

    li:last-child {
      margin-bottom: $--clb-space-4;
    }
  }

  &__audience-attribute-row {
    padding: $--clb-space-1 $--clb-space-5;
    text-transform: capitalize;
    width: 100%;
    display: flex;
    font-size: $--clb-font-size-sm;
    color: $--clb-body-font;
    user-select: none;
  }

  &__audience-attribute {
    &-info {
      width: 100%;
      display: flex;
      white-space: nowrap;

      > .app-text {
        margin-right: $--clb-space-1;
      }

      &::before {
        content: '•';
        margin-right: $--clb-space-2;
        font-weight: $--clb-font-weight__bolder;
        color: $--clb-color-grey__grey-light;
      }
    }

    &-name {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__audience-row {
    cursor: pointer;
    border-bottom: 1px solid $--clb-color-grey__grey-lighter;
    transition: all 200ms ease-in-out;

    @apply tw-bg-white sm:hover:tw-bg-jb-grey-hover;

    &--expanded {
      .el-icon-arrow-down {
        color: $--clb-color-primary;
        transform: rotate(180deg);
      }
    }

    &--hidden {
      @apply tw-bg-grey-white-ter hover:tw-bg-grey-white-ter sm:hover:tw-bg-grey-white-ter;

      .performance-audience-selection__audience-name {
        color: $--clb-color-grey__grey;
      }

      .performance-audience-selection__audience-attribute-row {
        opacity: 0.5;
      }

      .performance-audience-selection__audience-actions {
        .el-icon-view {
          color: $--clb-color-grey__grey-light;

          &:hover {
            color: $--clb-color-grey__grey-darker;
          }
        }
      }
    }
  }

  &__audience-list {
    overflow: hidden;

    &--empty {
      text-align: center;
      padding: $--clb-layout-5 $--clb-space-3;

      p {
        color: $--clb-color-info;
      }

      .el-icon-circle-plus {
        color: $--clb-color-primary;
        cursor: pointer;
      }

      .app-button {
        margin: 1em auto;
      }
    }
  }

  .el-scrollbar {
    &__wrap {
      overflow-x: hidden;
      margin-bottom: 0 !important;
      overscroll-behavior: contain;
    }

    &__bar.is-horizontal {
      display: none;
    }
  }

  &__popover {
    &-button {
      margin: $--clb-space-4 0 0 auto;
    }
  }
}

.el-dropdown-menu__item.audience-dropdown {
  padding: $--clb-space-2 $--clb-space-3;
  line-height: 1.5;
  transition: color 0.2s ease-in-out;
  cursor: pointer;
}

