
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        








































.performance-reporting-overlay {
  background: $--clb-color-primary__white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid $--clb-color-grey__grey-lighter;
  width: 100%;
  height: 400px;

  @apply tw-py-layout-4 tw-px-layout-1;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-icon {
      width: 150px;
    }
  }
}
