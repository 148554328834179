
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        


































































































































































.performance-crowd-selection {
  &__heading {
    padding: 0 $--clb-layout-1;

    &--title {
      margin: 0;
      font-size: $--clb-h3__font-size;
      text-transform: capitalize;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    padding: $--clb-space-4;
    border-top: 1px solid $--clb-color-grey__grey-lighter;
    border-bottom: 1px solid $--clb-color-grey__grey-lighter;
    margin-top: $--clb-space-4;
    z-index: 1;
    box-shadow: 0 30px 50px 0 rgba(96, 96, 99, 0.05);

    &--add-item {
      min-width: $--clb-layout-3;
      display: flex;
      align-items: center;

      .el-icon-circle-plus {
        color: $--clb-color-primary;
        cursor: pointer;
      }
    }

    &--search {
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: $--clb-space-4;
      border-left: 1px solid $--clb-color-grey__grey-lighter;

      .el-icon-search {
        font-size: $--clb-font-size-sm;
      }

      input {
        padding-left: $--clb-space-4;
        border: none;
        font-size: $--clb-font-size-sm;

        &::placeholder {
          text-transform: capitalize;
          color: $--color-text-jb-placeholder;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  &__cascade {
    position: relative;
    width: 100%;
    flex: 1;
  }

  &__crowd-list {
    position: relative;
    width: 100%;

    &--empty {
      text-align: center;
      padding: $--clb-layout-5 $--clb-space-3;

      .el-icon-circle-plus {
        color: $--clb-color-primary;
        cursor: pointer;
      }

      .app-button {
        margin: 1em auto;
      }
    }

    &.disabled {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 105px;
      text-align: center;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid $--clb-color-secondary__light;
      font-size: $--clb-font-size-sm;
      line-height: $--clb-font-size-base;
      color: $--clb-border-color-dark;
    }
  }

  &__crowd-row {
    border-bottom: 1px solid $--clb-color-grey__grey-lighter;
    transition: 200ms ease-in-out;

    &--starred {
      .el-icon-star-on,
      .el-icon-star-on:hover {
        color: $--clb-color-primary !important;
      }
    }

    &--empty .app-text {
      line-height: $--clb-p__line-height * 2 !important;
    }

    &-wrapper {
      padding: $--clb-space-4;
      background-color: $--clb-color-primary__white;
      text-transform: capitalize;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      cursor: pointer;
      transition: background-color 200ms ease-in-out;

      @apply tw-bg-white sm:hover:tw-bg-jb-grey-hover;

      &.disabled {
        pointer-events: none;
      }
    }
  }

  &__crowd-info {
    display: flex;
    font-weight: $--clb-font-weight__semi-bold;
    max-width: calc(100% - 47px);
    flex-direction: column;
    align-items: flex-start;
    font-size: $--clb-font-size-sm;

    &__constraint {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-id {
        margin-right: $--clb-space-2;
      }

      &-name {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
    }

    .el-icon-arrow-down {
      margin-left: $--clb-space-2;
      color: $--clb-color-grey__grey-light;
      font-weight: $--clb-font-weight__semi-bold;
      transform-origin: center;
      transition: 200ms ease-in-out;
    }
  }

  &__crowd-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__crowd-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 47px;

    .el-icon-star-on,
    .el-icon-star-off,
    .el-icon-more {
      cursor: pointer;
      color: $--clb-color-grey__grey-light;
      transition: 200ms ease-in-out;

      &:hover {
        color: $--clb-color-grey__grey;
      }
    }

    .el-icon-star-on,
    .el-icon-star-off {
      font-size: $--clb-font-size-base;
      font-weight: $--clb-font-weight__bold;
    }

    .el-checkbox {
      .el-checkbox__input {
        visibility: hidden;
        position: absolute;
        top: -9999px;
        left: -9999px;
        z-index: -1;
      }

      .el-checkbox__label {
        padding: 0 $--clb-space-2;
      }
    }

    .el-icon-more {
      transform: rotate(90deg);
    }

    ul.el-dropdown-menu.el-popper {
      cursor: pointer;
      padding: $--clb-space-2 $--clb-space-3 $--clb-space-3 $--clb-space-3;
      transition: background-color 0.2s ease-in-out;

      &__item {
        :hover {
          background: $--clb-color-secondary__light;
        }
      }
    }
  }

  .el-scrollbar {
    &__wrap {
      overflow-x: hidden;
      margin-bottom: 0 !important;
      overscroll-behavior: contain;
    }

    &__bar.is-horizontal {
      display: none;
    }
  }
}

.el-dropdown-menu__item.crowd-dropdown {
  padding: $--clb-space-2 $--clb-space-3;
  line-height: 1.5;
  transition: color 0.2s ease-in-out;
  cursor: pointer;
}
