
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        































































































































































































































.performance-analytics-report {
  @include page-layout;

  &__title {
    display: flex;
    justify-content: space-between;
    margin: 0 auto $--clb-layout-3;

    &--wrap {
      flex: 7 1 auto;
      padding-right: $--clb-space-4;

      h3 {
        margin-top: 0;
        margin-bottom: $--clb-space-2;
      }

      h4 {
        color: $--clb-color-grey__grey-darker;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .app-button {
      display: flex;
      align-self: center;
      width: 182px;
      white-space: nowrap;

      i {
        margin-right: $--clb-space-2;
      }
    }
  }

  &__content {
    width: 100%;
    padding: 0;
    margin: 0 auto;
    position: relative;

    &-close {
      position: absolute;
      top: 8px;
      right: 13px;
    }

    @include lg-up {
      align-content: space-between;
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
    }
  }

  .performance-analytics-report__title h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .performance-reporting-instructions {
    padding: $--clb-layout-1;

    @include sm-up {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: $--clb-layout-3;
    }

    @include lg-up {
      display: block;
      flex-basis: 250px;
      flex-grow: 1;
      margin-left: $--clb-layout-3;
    }

    p {
      hyphens: none;
      line-height: $--clb-base__line-height;
    }
  }

  .performance-reporting-overlay {
    width: 100%;
    height: auto;
    min-height: 300px;
    margin-bottom: $--clb-layout-3;

    @include lg-up {
      min-height: 600px;
      align-self: stretch;
      flex-basis: calc(100% - 250px - #{$--clb-layout-3});
      flex-grow: 1;
      margin-bottom: 0;
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: $--clb-space-4;
    overflow: hidden;

    &-header--location {
      font-size: $--clb-font-size-sm;
      color: $--clb-color-grey__grey-dark;
      padding: $--clb-space-1;
      margin: 1px $--clb-space-3;
      line-height: $--clb-font-size-base;
      align-self: flex-start;
      white-space: nowrap;
      cursor: pointer;

      &,
      i {
        transition: $--all-transition;
      }

      i {
        display: inline-block;
        color: $--clb-color-grey__grey-dark;
      }

      &:hover {
        color: $--clb-color-grey__grey-darker;

        i {
          color: $--clb-color-primary;
          transform: translateX(-3px);
        }
      }
    }

    > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  }
}
