
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        










































































































.date-picker-filter {
  width: 100%;
  min-width: 220px;

  &__tabs {
    background: $--clb-color-primary__white;
    padding: $--clb-space-2 - 2px;
    border-radius: $--clb-border-radius;
    border: 1px solid $--clb-disabled-color;
  }

  &__tabs-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  &__tabs-list-item {
    outline: none;
    margin: 0 2px;
    border-radius: $--clb-border-radius;
    border: 1px solid transparent;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    text-align: center;
    color: $--clb-body-font;
    text-transform: uppercase;
    font-size: $--clb-font-size-xs;
    line-height: $--clb-layout-2;
    overflow: hidden;
    text-overflow: ellipsis;

    &.active,
    &.active:hover {
      background: $--clb-btn-bg-color;
      color: $--clb-color-primary__white !important;
    }

    &:hover {
      background: $--clb-color-secondary__light;
      color: $--clb-btn-bg-color;
      border: 1px solid $--clb-btn-bg-color;
      cursor: pointer;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__calendar {
    margin-top: $--clb-space-3;

    .el-input__inner {
      min-height: 40px !important;

      .el-range-separator {
        height: 25px;
      }
    }
  }

  &--hidden-date-picker {
    position: relative;

    .date-picker-filter__calendar {
      margin: 0;
      height: 0;
      overflow-y: hidden;
      transform: translateY(-40px);
    }
  }
}

@media (max-width: $--sm) {
  .el-date-range-picker.has-sidebar {
    width: 365px !important;
    min-width: 365px !important;
  }

  .el-picker-panel__body,
  .el-date-range-picker .el-picker-panel__body {
    width: 254px !important;
    min-width: 254px !important;
  }

  .el-picker-panel__icon-btn {
    padding-left: 6px;
    padding-right: 6px;
  }

  .el-date-range-picker__header div {
    white-space: nowrap;
    overflow: hidden;
  }

  .el-date-range-picker__content {
    padding: 8px;
  }

  .el-date-range-picker__content.is-right {
    width: 0;
    margin: 0;
    padding: 0;
    display: none;
  }

  .el-date-range-picker__content.is-left {
    width: 100%;
  }

  .el-date-range-picker__content.is-right table {
    display: none;
  }

  .el-date-range-picker__content.is-right .el-date-range-picker__header {
    position: absolute;
    left: -70px;
    top: 8px;
    display: block;
    width: 60px;
  }

  .el-date-range-picker__content.is-right .el-date-range-picker__header > div {
    display: none;
  }
}
