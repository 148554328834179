
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        



































































































































































.performance-crowd-constraint-selection {
  &__heading {
    padding: 0 $--clb-space-4;

    &--title {
      margin: 0;
      margin-bottom: $--clb-space-2;
      font-size: $--clb-h3__font-size;
      text-transform: capitalize;
      display: flex;

      span {
        cursor: pointer;
        display: flex;
        align-self: center;
        font-size: $--clb-font-size-base;
        color: $--clb-body-font;
        padding-left: $--clb-space-1;
      }
    }

    &--picker {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: $--clb-space-3;
      margin-bottom: $--clb-space-4;

      .date-picker-filter__calendar {
        margin-top: $--clb-space-2;
      }

      .el-input__inner {
        min-height: 40px !important;

        .el-range-separator {
          height: 25px;
        }
      }

      .border-error {
        box-shadow: 0 0 0 1px $--clb-color-danger;
        border-radius: $--clb-space-1;

        .date-picker-filter__tabs-list-item.active {
          background: $--clb-color-danger;
        }

        .date-picker-filter__tabs-list-item:hover {
          border-color: $--clb-color-danger;
        }
      }
    }
  }

  .el-input {
    padding: 0 $--clb-space-4;

    input {
      height: 40px;
      font-size: $--clb-font-size-sm;
      min-height: 40px !important;
    }
  }

  &__controls {
    position: relative;
    display: flex;
    align-items: center;
    border-top: 1px solid $--clb-color-grey__grey-lighter;
    border-bottom: 1px solid $--clb-color-grey__grey-lighter;
    height: 32px + $--clb-space-4;
    z-index: 1;
    box-shadow: 0 30px 50px 0 rgba(96, 96, 99, 0.05);

    &--search {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: calc(100% - #{32px + $--clb-space-4});
      padding-left: $--clb-space-4;
      transition: transform 0.2s ease-out, border 0.2s ease-out;
      transform: translateX(48px);
      border-left: 1px solid $--clb-color-grey__grey-lighter;

      &.full-width {
        max-width: 100%;
        border-left-color: transparent;
        transform: translateX(0);
      }

      .el-icon-search {
        font-size: $--clb-font-size-sm;
      }

      input {
        padding: 0 $--clb-space-4;
        border: none;
        font-size: $--clb-font-size-sm;
        width: 100%;

        &::placeholder {
          text-transform: capitalize;
          color: $--color-text-jb-placeholder;
        }

        &:focus {
          outline: none;
        }
      }
    }

    &-back {
      position: absolute;
      top: 0;
      left: 0;
      width: $--clb-layout-3;
      padding: $--clb-space-2;
      margin: $--clb-space-2;
      cursor: pointer;
    }
  }

  &__cascade {
    position: relative;
    width: 100%;
    flex: 1;

    &-list {
      width: 100%;

      &--error {
        padding: $--clb-layout-5 $--clb-space-4;
        font-size: $--clb-font-size-sm;
        text-align: center;

        span {
          font-size: $--clb-font-size-sm;
          font-weight: $--clb-font-weight__bold;
        }
      }
    }

    &-list--item {
      padding: $--clb-space-4;
      border-bottom: 1px solid $--clb-color-grey__grey-lighter;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      transition: background-color 200ms ease-in-out;

      @apply tw-bg-white sm:hover:tw-bg-jb-grey-hover;

      &__percentage {
        letter-spacing: 0.5px;
        text-transform: lowercase;
      }

      &__constraint-name {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .el-icon-circle-plus {
        color: $--clb-color-primary;
      }

      .el-icon-arrow-right {
        color: $--clb-color-grey__grey-dark;
      }

      &--empty {
        pointer-events: none;
      }
    }
  }

  .el-scrollbar {
    &__wrap {
      overflow-x: hidden;
      margin-bottom: 0 !important;
      overscroll-behavior: contain;
    }

    &__bar.is-horizontal {
      display: none;
    }
  }

  &__popover {
    &-button {
      margin: $--clb-space-4 0 0 auto;
    }
  }
}
