
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        











































.performance-reporting-instructions {
  background: $--clb-color-primary__white;
  border-radius: 4px;
  width: 100%;

  &__media-object {
    &--title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    h2 {
      margin-left: 11px;
    }

    i {
      margin-bottom: 3px;
      font-size: 19px;
    }
  }

  &__media-object .crowd-icon {
    width: $--clb-space-5;
    height: $--clb-layout-1;
  }

  @include lg-up {
    > div:nth-child(1) > div.performance-reporting-instructions__media-object--title > h2 {
      margin-top: 0 !important;
    }

    > div:nth-child(1) > div.performance-reporting-instructions__media-object--title > .crowd-icon {
      position: relative;
      top: -10px;
    }
  }
}
