
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        









































































































































































































































































































































.fixed-wrapper {
  width: 100%;
  overflow-x: auto;
  background: $--clb-color-primary__white;
  border: 1px solid $--clb-color-grey__grey-lighter;
  border-radius: $--clb-border-radius $--clb-border-radius 0 0;
  margin-bottom: $--clb-layout-3;

  &--empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: initial;
    height: 400px;
    text-align: center;
    padding: $--clb-layout-3;

    i {
      display: block;
      color: $--clb-color-primary;
      font-size: 76px;
      margin-bottom: $--clb-layout-2;
    }

    .app-text {
      line-height: 26px;
    }
  }
}

.performance-reporting-table {
  background: transparent;
  user-select: none;
  border-spacing: 0;
  width: 100%;

  tr {
    height: auto;
  }

  &__table-header {
    th {
      border-right: $--clb-border-complete-light;
      padding: $--clb-space-4 0;
      min-width: 4rem;
      vertical-align: bottom;

      &:last-child {
        border-right: 0;
        width: 100%;
      }

      span,
      svg {
        display: block;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        margin: 0 auto;
        transform: rotate(180deg);
        height: 16ch;
        max-height: 180px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__main-heading {
    @apply tw-bg-jb-indigo-darker tw-text-lg;

    &-title {
      color: $--clb-color-primary__white;
      font-weight: bold;
      padding: 0 $--clb-space-4;
      vertical-align: middle;
      height: 50px;
      text-transform: capitalize;
    }
  }

  &__event-label {
    background: $--clb-color-secondary__light;
    overflow-x: hidden;

    td {
      @extend .performance-reporting-table__main-heading-title;

      padding: $--clb-space-4;
      color: $--clb-color-accent__dark;
    }

    &-skeleton {
      margin: 0;
    }
  }

  &__event-group {
    transition: 200ms ease-in-out;

    &--collapsed > .performance-reporting-table__event-label > td {
      color: $--clb-color-grey__grey-dark;
    }
  }

  td.performance-reporting-table__metric-label {
    background: $--clb-color-primary__white;
    height: 50px;

    &-skeleton svg {
      margin: 0;
    }
  }

  tr.performance-reporting-table__event-label > td {
    background-color: $--clb-color-grey__white-ter;
    border-bottom: $--clb-border-complete-dark;
  }

  &__metric {
    &-row {
      position: relative;
      height: 50px;
      transition: 200ms ease-in-out;

      td {
        transition: 200ms ease-in-out, font-size 100ms ease-in-out 100ms;
        border-bottom: $--clb-border-complete-light;

        > * {
          transition: color 200ms ease-in-out;
        }
      }

      &.hidden {
        height: 0 !important;

        td {
          transition: 200ms ease-in-out, font-size 0ms;
          height: 0;
          border: 0 solid $--clb-border-color-lighter !important;
          font-size: 0 !important;
          line-height: 0;

          > * {
            color: transparent !important;
          }
        }
      }
    }

    &-label {
      color: $--clb-body-font;
      padding-left: $--clb-layout-3;
      text-align: left;
      display: flex;
      align-items: center;
      min-width: 210px;

      @include xs-up {
        min-width: 298px;
      }

      span {
        word-break: break-word;
      }

      &-spaceholder {
        td {
          height: $--clb-space-4;
        }
      }
    }

    &-cell {
      width: 4rem;
      padding: 0 0 0 1px;
      overflow: hidden;
      height: 50px;
      vertical-align: middle !important;

      &--active {
        border-bottom: 1px solid $--clb-color-primary__white !important;
      }

      &-skeleton {
        background: $--clb-color-grey__white-est;
      }

      .skeleton-box {
        width: 26px;
        margin: 0 auto;
      }

      &:last-child {
        width: 100%;
      }
    }
  }

  .el-icon-arrow-up,
  .el-icon-arrow-down {
    padding-left: $--clb-space-2;
    vertical-align: middle;
    font-size: $--clb-font-size-lg;
    font-weight: $--clb-font-weight__bolder;
    position: relative;
    top: -1px;
  }

  .el-icon-arrow-up {
    color: $--clb-color-grey__grey-dark;
  }
}

.table-overlay {
  z-index: 1;
  top: 0;
  left: 1px;
  position: absolute;
  overflow: hidden;
  width: 210px;
  display: block;
  border: 1px solid $--clb-color-grey__grey-lighter;
  border-right: 0;
  border-left: 0;
  border-radius: $--clb-border-radius;

  th:nth-child(1) {
    background: linear-gradient(to right, $--clb-color-primary__white, $--clb-color-primary__white, rgba(white, 0.75));
  }

  @include xs-up {
    width: 298px;
  }
}

.app-header__portal .fixed-wrapper,
.app-header__portal .header-overlay {
  position: relative;
  width: 100%;
  max-width: calc(100% - #{$--clb-space-4 * 2});
  margin: 0 auto;
  padding: 0;
  border: none;
  border-radius: 0;
  border-left: 1px solid $--clb-color-grey__grey-lighter;
  border-right: 1px solid $--clb-color-grey__grey-lighter;
  border-bottom: 1px solid $--clb-color-grey__grey-lighter;
  scrollbar-width: none;

  @media (min-width: $--sm) {
    max-width: calc(100% - #{$--clb-layout-4 * 2});

    ~ .header-overlay {
      left: $--clb-layout-4 + 1;
    }
  }

  @media (min-width: $--md) {
    max-width: calc(100% - #{($--clb-layout-2 + $--clb-layout-3) * 2});

    ~ .header-overlay {
      left: $--clb-layout-2 + $--clb-layout-3 + 1;
    }
  }

  @media (min-width: $--lg) {
    max-width: calc(100% - #{($--clb-layout-4 + $--clb-layout-3) * 2});

    ~ .header-overlay {
      left: $--clb-layout-4 + $--clb-layout-3 + 1;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .performance-reporting-table {
    border-top: none;
    border-bottom: none;
    width: 100%;
    margin-left: 0 !important;

    th:first-child {
      min-width: 210px;
    }

    @media (min-width: $--xs) {
      th:first-child {
        min-width: 298px;
      }
    }
  }
}

.app-header__portal .fixed-wrapper .overlay {
  display: none;

  @media (min-width: #{$--clb-app-content-width + $--clb-app-left-sidebar-width}) {
    display: block;
  }
}

.app-header__portal .header-overlay {
  position: absolute;
  top: 61px;
  left: 1px;
  height: calc(100% - 61px);
  width: #{210px - 1px};
  margin-right: 0 !important;
  border: none;

  @include xs-up {
    width: #{298px - 2px};
  }

  @media (min-width: $--md) {
    top: 0;
    height: calc(100% - 1px);
  }

  @media (min-width: #{$--clb-app-content-width + $--clb-app-left-sidebar-width}) {
    display: none;
  }
}

.app-header__portal .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: #{210px - 2px};
  background: linear-gradient(to right, $--clb-color-primary__white, $--clb-color-primary__white, rgba(white, 0.75));

  @media (min-width: $--xs) {
    width: #{298px - 1px};
  }
}
