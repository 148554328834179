
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        




















































.app-gradient-cell {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  color: $--clb-color-grey__grey-dark;
  font-size: $--clb-font-size-sm;
  letter-spacing: 0.5px;
  -webkit-font-smoothing: subpixel-antialiased;

  &.max {
    font-weight: 700;
  }

  &.inverted {
    color: white;
  }
}

