
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        

















.performance-crowd-selection-skeleton {
  pointer-events: none;
  height: 71px;
  padding: $--clb-space-4 $--clb-space-4 $--clb-space-4 $--clb-space-4;
  border-bottom: 1px solid $--clb-border-color-lighter;
  font-size: $--clb-font-size-sm !important;

  .skeleton-box {
    margin: 0;
    margin-bottom: $--clb-space-1;
  }
}
